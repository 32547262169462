import Vue from 'vue';
import pluralize from 'pluralize';

Object.defineProperties(Vue.prototype, {
    $pluralize: {
        get() {
            return pluralize
        }
    }
});


Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  })