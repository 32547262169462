import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import { firebase, db } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";

import router from "@/router";

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      console.log("setting user", data);
      state.user.data = data;
    },
  },
  actions: {
    async logout({ commit }) {
      commit("SET_USER", null);
      commit("SET_LOGGED_IN", false);

    },
    async fetchUser({ commit }, uid) {
      let rec = null;
      if (uid) {
        try {

          const result = await getDoc(doc(db, "users", uid));

          if (!result.exists) {
            firebase
              .auth()
              .signOut()
              .then(() => {
                router.push("/").catch(() => { });
              });
            return;
          }

          const userRecord = result.data();

          rec = {
            email: userRecord.email,
            uid: uid,
            roles: ["newblack"],
          };


          if (userRecord) {
            rec.givenName = userRecord.givenName;
            rec.surname = userRecord.surname;
            rec.displayName = userRecord.displayName || (userRecord.givenName + ' ' + userRecord.surname);
          }


          commit("SET_USER", rec);
        } catch (err) {

          if (err.code == "permission-denied") {
            alert(err);
            firebase
              .auth()
              .signOut()
              .then(() => {
                router.push("/").catch(() => { });
              });
          }
        }
        commit("SET_LOGGED_IN", rec !== null);
      } else {
        commit("SET_USER", null);
      }
    },
  },
});
