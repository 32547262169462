import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth } from "firebase/auth";


import config from "@/config";

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyBxgcbU6YHNEAwtOgGVFIvHs3658G8vLA0",
    authDomain: "new-black-life.firebaseapp.com",
    projectId: "new-black-life",
    storageBucket: "new-black-life.appspot.com",
    messagingSenderId: "368517504143",
    appId: "1:368517504143:web:368ad3e694bc16a27de4fa"
};

// utils

const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);
const functions = getFunctions(firebase, config.region);


const auth = getAuth(firebase);

if (window.location.hostname === "localhost") {
    connectFirestoreEmulator(db, "127.0.0.1", 8080);
    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}

export { firebase, db, auth, functions };

