<template>
  <div class="fullscreen" style="height: 100vh">
    <h1>HELLO WORLD</h1>
    <div
      ref="splash"
      style="
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      "
    >
    hasidsa
      <!-- <lottie-player
        v-if="loading"
        src="https://lottie.host/e4ca5485-df2d-41cd-a583-db3e7f95a857/r03ZeJfR43.json"
        background="transparent"
        speed="1"
        style="width: 100%; height: auto"
        autoplay
        id="lottie"
        ref="lottie"
      ></lottie-player> -->
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return { loading: true };
  },
  mounted() {
    this.$refs["lottie"].addEventListener("complete", () => {
      console.log("completed");
      //   setTimeout(() => {
      //     this.loading = false;
      //   }, 1000);
    });
  },
};
</script>