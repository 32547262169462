<template>
  <div class="dark">
    <div class="container"
         style="padding-top: 48px">
      <div class="row"
           style="padding-bottom: 60px; gap: 24px">
        <div class="column fadeIn"
             v-if="loaded && user.loggedIn && user.data">
          <h2>Hi, {{ user.data.givenName }}.</h2>

          <p class="bigger"
             style="margin-top: 24px">
            Let your colleagues know what you're up to.
          </p>

          <p style="margin-top: 50px">I will be in the office</p>
          <div style="margin-top: 20px">
            <button v-for="day in introDays"
                    v-bind:key="'1.' + day.date.toString()"
                    v-on:click="togglePresence(day)"
                    v-bind:class="{ active: day.presence }">
              <img src="@/assets/emoji/64/1f3e2.png"
                   alt="🏢"
                   class="icon" />
              {{ displayDate(day.date) }}
            </button>
          </div>

          <div v-for="day in introDays"
               v-bind:key="'2.' + day.date.toString()"
               style="margin-top: 20px">
            <div class="day"
                 v-bind:class="{ fadeIn: loaded }"
                 v-if="day.presence">
              <p>{{ displayDate(day.date) }} I would like</p>


              <button v-on:click="toggle(day, 'lunch')"
                      v-bind:class="{ active: day.amenities.lunch }"
                      :disabled="!isAmenityAvailable(day, 'lunch')">

                <template v-if="day.amenities.lunch && day.foodPreference == 'vegan'">
                  <img class="icon"
                       src="@/assets/emoji/64/1f96c.png"
                       alt="🥬" />Lunch ({{ day.foodPreference | capitalize }})
                </template>
                <template v-else-if="
                  day.amenities.lunch && day.foodPreference == 'vegetarian'
                ">
                  <img src="@/assets/emoji/64/1f96a.png"
                       alt="🥪"
                       class="icon" />Lunch ({{ day.foodPreference | capitalize }})
                </template>
                <template v-else>
                  <img src="@/assets/emoji/64/1f96a.png"
                       alt="🥪"
                       class="icon" />Lunch
                </template>
              </button>
              <button v-on:click="toggle(day, 'dinner')"
                      v-bind:class="{ active: day.amenities.dinner }"
                      :disabled="!isAmenityAvailable(day, 'dinner')">
                <template v-if="day.amenities.dinner && day.foodPreference == 'vegan'">
                  <img class="icon"
                       src="@/assets/emoji/64/1f37d-fe0f.png"
                       alt="🍽️" />
                  Dinner ({{ day.foodPreference | capitalize }})
                </template>
                <template v-else-if="
                  day.amenities.dinner && day.foodPreference == 'vegetarian'
                ">
                  <img class="icon"
                       src="@/assets/emoji/64/1f37d-fe0f.png"
                       alt="🍽️" />
                  Dinner ({{ day.foodPreference | capitalize }})
                </template>
                <template v-else>
                  <img class="icon"
                       src="@/assets/emoji/64/1f37d-fe0f.png"
                       alt="🍽️" />Dinner
                </template>
              </button>
              <button :disabled="(day.availableParkingSpots == 0 &&
                !day.amenities.parkingSpot) ||
                day.pendingTasks.parkingSpot ||
                !isAmenityAvailable(day, 'parking')
                "
                      v-bind:class="{
                        active: day.amenities.parkingSpot,
                        processing: day.pendingTasks.parkingSpot,
                      }"
                      v-on:click="toggleParking(day)">
                <img class="icon"
                     v-if="!day.pendingTasks.parkingSpot"
                     src="@/assets/emoji/64/1f698.png"
                     alt="🚘" /><img class="icon"
                     v-if="day.pendingTasks.parkingSpot"
                     src="@/assets/spinner.gif" />Parking spot
                <span class="button-number">{{
                  day.availableParkingSpots == 0 && !day.amenities.parkingSpot
                    ? "full"
                    : day.availableParkingSpots + " left"
                }}</span>
              </button>

              <button class="bdg-inline"
                      v-bind:class="{ active: day.amenities.guests > 0 }"
                      v-on:click="addGuest(day)"
                      nbr="4"
                      :disabled="!isAmenityAvailable(day, 'guests')">
                <img class="icon"
                     src="@/assets/emoji/64/1f465.png"
                     alt="👥" />Guests
                <div v-if="day.amenities.guests"
                     class="bdg-number-inline">
                  {{ day.amenities.guests }}
                </div>
              </button>
            </div>
          </div>
          <div v-if="showMoreOptions"
               class="fadeIn"
               style="margin-top: 20px">
            <p>Choose options till {{ lastDate.format("MMMM DD") }}</p>
            <table class="more-options">
              <tbody>
                <template v-for="day in schedule">
                  <tr v-bind:key="day.date.toString() + '-main'">

                    <td class="presence">

                      <button class="square"
                              v-on:click="togglePresence(day)"
                              v-bind:class="{ active: day.presence }">
                        <img class="icon"
                             src="@/assets/emoji/64/1f3e2.png"
                             alt="🏢" />
                      </button>
                    </td>
                    <td class="day">
                      <span class="bigger">{{ displayDate(day.date) }}</span><span class="date">{{
                        day.date.format("MMMM DD")
                      }}</span>
                    </td>
                    <td>
                      <button class="square"
                              v-on:click="toggle(day, 'lunch')"
                              v-bind:class="{ active: day.amenities.lunch }"
                              :disabled="!isAmenityAvailable(day, 'lunch')">
                        <img class="icon"
                             src="@/assets/emoji/64/1f96a.png"
                             alt="🥪" />
                      </button>
                      <button class="square"
                              v-on:click="toggle(day, 'dinner')"
                              v-bind:class="{ active: day.amenities.dinner }"
                              :disabled="!isAmenityAvailable(day, 'dinner')">
                        <img class="icon"
                             src="@/assets/emoji/64/1f37d-fe0f.png"
                             alt="🍽️" />
                      </button>
                      <button class="square"
                              v-on:click="toggleParking(day)"
                              :disabled="(day.availableParkingSpots == 0 &&
                                !day.amenities.parkingSpot) ||
                                day.pendingTasks.parkingSpot ||
                                !isAmenityAvailable(day, 'parking')
                                "
                              v-bind:class="{
                                active: day.amenities.parkingSpot,
                                processing: day.pendingTasks.parkingSpot,
                              }">
                        <img class="icon"
                             v-if="!day.pendingTasks.parkingSpot"
                             src="@/assets/emoji/64/1f698.png"
                             alt="🚘" /><img class="icon"
                             v-if="day.pendingTasks.parkingSpot"
                             src="@/assets/spinner.gif"
                             alt="🚘" />
                      </button>

                      <button class="square"
                              v-bind:class="{ active: day.amenities.guests > 0 }"
                              v-on:click="addGuest(day)"
                              :disabled="!isAmenityAvailable(day, 'guests')">
                        <img class="icon"
                             src="@/assets/emoji/64/1f465.png"
                             alt="👥" />

                        <div v-if="day.amenities.guests"
                             class="bdg-number-inline">
                          {{ day.amenities.guests }}
                        </div>
                      </button>

                      <button :disabled="!day.presence"
                              class="square"
                              v-on:click="day.showRemarks = !day.showRemarks"
                              v-bind:class="{
                                active: day.remarks && day.remarks.length > 0,
                              }">
                        <img class="icon"
                             src="@/assets/emoji/64/1f4dd.png"
                             alt="📝" />
                      </button>
                    </td>
                  </tr>
                  <tr v-if="day.showRemarks"
                      v-bind:key="day.date.toString() + '-remarks'">
                    <td colspan="3"
                        class="remarks">
                      <input type="text"
                             v-model="day.remarks"
                             placeholder="Enter your remarks"
                             v-on:change="update(day)" />
                    </td>
                  </tr>

                </template>
              </tbody>
            </table>
          </div>
          <button style="margin: 20px 0px"
                  @click="showMoreOptions = !showMoreOptions">
            <img class="icon"
                 src="@/assets/emoji/64/1f5d3-fe0f.png"
                 alt="🗓️" /><span v-if="showMoreOptions">Hide Options</span>
            <span v-else>More Options</span>
          </button>
        </div>
        <div class="one-third column tower sm:hidden">
          <lottie-player src="/assets/nblife-wtc.json"
                         background="transparent"
                         speed="1"
                         style="width: 100%; height: 600px;max-height:600px;"
                         autoplay
                         ref="lottie-wtc"></lottie-player>
        </div>
      </div>
    </div>
    <section id="details">
      <div class="container content-item top with-border"
           style="padding-top: 48px">
        <div style="display: flex; flex-direction: column"
             class="birthdays">
          <h3>Birthdays</h3>

          <div v-if="upcomingBirthdays.length > 0">
            <div style="
                  display: flex;
                  flex-direction: row;
                  align-items: stretch;
                  margin-bottom: 12px;
                "
                 v-for="(b, idx) in upcomingBirthdays"
                 v-bind:key="idx">
              <div>
                <img style="width: 18px; height: 18px; margin-right: 16px"
                     src="@/assets/birthday-icon.svg" />
              </div>
              <div>
                <b> {{ b.givenName }}</b>
                <p>{{ formatBirthday(b.nextBirthday) }}</p>
              </div>
            </div>
          </div>
          <div v-else>
            <div style="
                  display: flex;
                  flex-direction: row;
                  align-items: stretch;
                  margin-bottom: 12px;
                ">
              <div>
                <img style="width: 18px; height: 18px; margin-right: 16px"
                     src="@/assets/birthday-icon.svg" />
              </div>
              <div>
                <b>No birthdays found</b>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; flex-direction: column">
          <h3>Updates</h3>
          <div>
            <div style="display: flex; flex-direction: row; align-items: stretch">
              <div>
                <svg width="18"
                     height="18"
                     viewBox="0 0 18 18"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     style="margin-right: 16px">
                  <path d="M0 8.95188C0 10.7951 0.894876 11.8906 2.56822 12.1275L4.21974 15.8287C4.64171 16.7836 5.23102 17.25 6.11135 17.25C7.25358 17.25 8.07571 16.4135 7.82834 15.1033L7.31906 12.394C9.37801 12.8011 11.4515 13.5636 13.3649 14.6814C13.6268 15.4808 14.3326 15.999 15.2565 15.999C16.4279 15.999 17.25 15.1625 17.25 13.9633V2.78567C17.25 1.58647 16.4279 0.75 15.2565 0.75C14.3107 0.75 13.5905 1.29778 13.3431 2.13425C11.095 3.4889 8.22121 4.37719 5.92946 4.62887H3.27394C1.14952 4.62887 0 5.76144 0 7.84892V8.95188ZM14.6381 13.9633V2.78567C14.6381 2.41554 14.8855 2.15646 15.2565 2.15646C15.6203 2.15646 15.8677 2.41554 15.8677 2.78567V13.9633C15.8677 14.3334 15.6203 14.5925 15.2565 14.5925C14.8855 14.5925 14.6381 14.3334 14.6381 13.9633ZM6.155 10.8025V5.99832C8.70867 5.68742 11.1314 4.94717 13.2558 3.73318V13.075C11.0877 11.8314 8.68684 11.1134 6.155 10.8025ZM3.27394 10.7655C1.92071 10.7655 1.38233 10.2473 1.38233 8.95188V7.84892C1.38233 6.5535 1.92071 6.03533 3.27394 6.03533H4.77267V10.7655H3.27394ZM5.51476 15.4364L4.00148 12.1719H5.88581C5.90763 12.1719 5.93674 12.1793 5.96584 12.1793L6.56242 15.281C6.64245 15.6733 6.42419 15.925 6.0968 15.925C5.80578 15.925 5.66755 15.7695 5.51476 15.4364Z"
                        fill="url(#paint0_linear_3778_3731)" />
                  <defs>
                    <linearGradient id="paint0_linear_3778_3731"
                                    x1="0"
                                    y1="0.75"
                                    x2="19.9234"
                                    y2="5.15301"
                                    gradientUnits="userSpaceOnUse">
                      <stop stop-color="#B14CEE" />
                      <stop offset="1"
                            stop-color="#E75381" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div>
                <b><i>Nothing to report</i></b>
                <!-- <p>
                  Looking forward to post new New Black updates here!
                  So far Easter is coming up; and it’s slowly getting warmer and the birds are chirping. Happy springtime
                  everyone!
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container content-item">
        <div><img src="@/assets/media-small.png" /></div>
        <div class="description">
          <h1>Media</h1>
          <p class="bigger">
            Feeling nostalgic already? We get it; we’ve got you covered. Check
            out the media section to re-experience our latest New Black Event
            and let the good times roll in HD.
          </p>
          <a href="https://photos.app.goo.gl/Y7iSo73QkhjWVW9n6"
             target="_new">View all photos and videos</a>
        </div>
      </div>
      <div class="container content-item">
        <div class="description">
          <h1>Wine</h1>
          <p class="bigger">
            New White, New Red and New Rosé. Each new wine selected by
            Restaurant the Boet to be available at the office will be added
            here. Check it out to confirm whatever it is you connoisseurs are
            tasting. Pardon our Dutch.
          </p>
          <router-link :to="{ name: 'page', params: { id: 'wine' } }">Explore the wines</router-link>
        </div>
        <div><img src="@/assets/wine.svg" /></div>
      </div>
      <div class="container content-item">
        <div><img src="@/assets/coffee.svg" /></div>
        <div class="description">
          <h1>Coffee</h1>
          <p class="bigger">
            If it’s fuel that you need, look no further. Our Lifesupply page
            will show you just what kind of coffee it is you’re drinking. Go
            ahead and try something new every now and then: our wide selection
            ensures no palate is left behind.
          </p>
          <router-link :to="{ name: 'page', params: { id: 'coffee' } }">Explore the coffee beans</router-link>
        </div>
      </div>
      <div class="container content-item">
        <div class="description">
          <h1>Boat</h1>
          <p class="bigger">
            Horsepower, location, reservations and most importantly: etiquette.
            Anything you need to go out boating with our Clubmaster 30 RIB can
            be found right here.
          </p>
          <router-link :to="{ name: 'page', params: { id: 'boat' } }">All the info you need to set sail</router-link>
        </div>
        <div class="lottie-element">
          <lottie-player src="/assets/nblife-boat.json"
                         background="transparent"
                         speed="0.5"
                         style="width: 100%; height: auto"
                         autoplay
                         loop></lottie-player>
          <!--           
          <img src="@/assets/boat.svg" /> -->
        </div>
      </div>
      <div class="container content-item">
        <div><img src="@/assets/hotel.svg" /></div>
        <div class="description">
          <h1>Hotel</h1>
          <p class="bigger">
            You’re free to work whenever and wherever. Nevertheless, we like
            seeing you at the office. We’ve made this a tad easier with our own
            New Black hotel. Feel free to stay whenever you like.
          </p>
          <router-link :to="{ name: 'page', params: { id: 'hotel' } }">Book a room</router-link>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { db, functions } from "@/firebase";
import { httpsCallable } from 'firebase/functions';
import { collection, doc, getDoc, query, where, setDoc, deleteDoc, onSnapshot } from 'firebase/firestore';

import { mapGetters } from "vuex";
import config from "@/config";
import confetti from "canvas-confetti";

class Registration {
  constructor(date, config) {
    this.pendingTasks = { parkingSpot: false };
    this.date = date;
    this.presence = false;
    this.showRemarks = false;
    this.remarks = null;
    this.foodPreference = "";
    this.allergies = [];
    this.availableParkingSpots = config.parkingSpotCapacity;
    this.reset();
  }
  reset() {
    this.amenities = {
      breakfast: false,
      lunch: false,
      dinner: false,
      guests: 0,
      parkingSpot: false,
    };
    this.allergies = [];
    this.foodPreference = "";
    this.remarks = null;
  }

  get hasMeal() {
    return (
      this.amenities.breakfast || this.amenities.lunch || this.amenities.dinner
    );
  }
}


export default {
  setup() { },
  methods: {
    formatBirthday(birthday) {

      const bd = birthday.toDate();
      return this.$date(
        new Date(2022, bd.getMonth(), bd.getDate())
      ).format("MMMM DD");
    },
    displayDate(date) {
      if (date.isToday()) return "Today";
      if (date.isTomorrow()) return "Tomorrow";

      return date.format("dddd");
    },
    async toggleParking(reg) {
      reg.pendingTasks.parkingSpot = true;

      const functionName = reg.amenities.parkingSpot
        ? "parkingRelease"
        : "parkingReserve";
      var func = httpsCallable(functions, functionName);


      func({
        date: parseInt(reg.date.format("YYYYMMDD")),
        uid: this.user.data.uid,
      }).then((result) => {
        console.log('call result', result)
        if (result.data) {
          reg.availableParkingSpots = result.data.availableParkingSpots;
          reg.amenities.parkingSpot = result.data.amenities.parkingSpot;
        }
        reg.pendingTasks.parkingSpot = false;
      }).catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
      });
    },
    togglePresence(reg) {
      reg.presence = !reg.presence;
      !reg.presence && reg.reset();

      if (reg.presence) reg.isNew = true;
      this.update(reg);
    },
    toggle(reg, amenity) {
      reg.amenities[amenity] = !(reg.amenities[amenity] || false);

      if (!reg.hasMeal) {
        reg.amenities.guests = 0;
        reg.amenities.foodPreference = "";
      } else {
        reg.foodPreference = this.profile.foodPreference || "";
        reg.allergies = this.profile.allergies || {
          wheat: false,
          dairy: false,
        };
      }

      if (reg.amenities[amenity]) reg.presence = true;

      this.update(reg);
    },
    addGuest(reg) {
      if (reg.amenities.guests <= 8) reg.amenities.guests++;
      else reg.amenities.guests = 0;
      this.update(reg);
    },
    async update(reg) {
      var key = reg.date.format("YYYYMMDD") + "_" + this.user.data.uid;
      const regRef = doc(db, "registrations", key);
      if (reg.presence) {

        const payload = {
          foodPreference: reg.foodPreference,
          allergies: reg.allergies,
          presence: reg.presence,
          amenities: reg.amenities,
          uid: this.user.data.uid,
          user: this.user.data,
          date: parseInt(reg.date.format("YYYYMMDD")),
          remarks: reg.remarks || null,
        }

        await setDoc(regRef,
          payload
        );
      } else {
        await deleteDoc(regRef);

      }
    },
    isAmenityAvailable(reg) {

      let cutoff = this.$date.tz(reg.date.format('YYYY-MM-DD 08:00'), 'Europe/Amsterdam')

      if (
        this.$date().format('YYYYMMDD') == reg.date.format("YYYYMMDD") // today 
        && this.$date().utc().hour() >= cutoff.utc().hour()
      ) {
        return false;
      }

      return true;
    },
    checkForParty() {
      if (
        this.upcomingBirthdays.length &&
        // isToday ? 
        this.upcomingBirthdays[0].nextBirthday.toDate().getDate() == this.$date().date() &&
        this.upcomingBirthdays[0].nextBirthday.toDate().getMonth() == this.$date().month()
      ) {
        setTimeout(() => {
          var count = 200;
          var defaults = {
            origin: { y: 0.7 },
          };

          function fire(particleRatio, opts) {
            confetti(
              Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio),
              })
            );
          }

          fire(0.25, {
            spread: 26,
            startVelocity: 55,
          });
          fire(0.2, {
            spread: 60,
          });
          fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
          });
          fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
          });
          fire(0.1, {
            spread: 120,
            startVelocity: 45,
          });
        }, 2000);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    introDays() {
      return this.schedule.slice(0, 3);
    },
    lastDate() {
      return this.$date().add(config.scheduleDays, "day");
    },
  },
  data() {
    return {
      schedule: [],
      showMoreOptions: true,
      registrationData: [],
      profile: {},
      loaded: false,
      upcomingBirthdays: [],
    };
  },

  async mounted() {
    this.schedule = [];

    const cutoff = this.$date().add(config.scheduleDays, "day");
    let currentDate = this.$date();
    let offset = 0;

    console.log("loading");
    while (offset <= config.scheduleDays) {
      let d = currentDate.add(offset, "day");

      offset++;
      if (d.day() == 0 || d.day() == 6) continue;

      this.schedule.push(new Registration(d, config));
    }



    const userProfile = await getDoc(doc(db, "users", this.user.data.uid));



    if (userProfile.exists) {
      this.profile = userProfile.data();
    }
    console.log(this.profile);

    const q = query(collection(db, "registrations"),
      where("date", "<=", parseInt(cutoff.format("YYYYMMDD"))),
      where("date", ">=", parseInt(this.$date().format("YYYYMMDD"))),
      where("uid", "==", this.user.data.uid)

    );

    onSnapshot(q, (registrationSnapshot) => {

      registrationSnapshot.forEach(r => {
        const document = r.data();

        const record = this.schedule.find(
          (x) => parseInt(x.date.format("YYYYMMDD")) == document.date
        );
        if (!record) return;
        record.amenities = document.amenities;
        record.presence = document.presence;
        record.remarks = document.remarks;
        record.foodPreference = document.foodPreference;
        record.allergies = document.allergies;

        if (document.availableParkingSpots != null)
          record.availableParkingSpots = document.availableParkingSpots;
      });
      this.loaded = true;
    });


    // await collection(db, "registrations")
    //   .where("date", "<=", parseInt(cutoff.format("YYYYMMDD")))
    //   .where("date", ">=", parseInt(this.$date().format("YYYYMMDD")))
    //   .where("uid", "==", this.user.data.uid)
    //   .onSnapshot((snapshot) => {
    //     snapshot.docs
    //       .map((x) => x.data())
    //       .forEach((doc) => {
    //         const record = this.schedule.find(
    //           (x) => parseInt(x.date.format("YYYYMMDD")) == doc.date
    //         );
    //         if (!record) return;
    //         record.amenities = doc.amenities;
    //         record.presence = doc.presence;
    //         record.remarks = doc.remarks;
    //         record.foodPreference = doc.foodPreference;
    //         record.allergies = doc.allergies;

    //         if (doc.availableParkingSpots != null)
    //           record.availableParkingSpots = doc.availableParkingSpots;
    //       });
    //   });

    const birthdaysDoc = await getDoc(doc(db, "documents", "birthdays"));
    const birthdays = birthdaysDoc.data().entries;


    this.upcomingBirthdays = (birthdays && birthdays.length && birthdays) || [];

    console.log("loaded");
    this.checkForParty();


  },
};
</script>
