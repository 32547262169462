import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";

Vue.config.productionTip = false;

import axios from "axios";
axios.defaults.baseURL = config.baseUrl;
Vue.prototype.$http = axios;

import config from "./config";
Vue.use(VueRouter);

import router from "./router";

import store from "./store";
import { auth } from "./firebase";
import "@/plugins/dayjs";
import "@/plugins/pluralize";

import "@/style.scss";

let app;
auth.onAuthStateChanged(async (user) => {
  console.log("auth changed", user);



  await store.dispatch("fetchUser", user?.uid);

  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
