<template>
  <div style="display: flex; flex: 1; flex-direction: column; border: none;">
    <!-- <iframe
    src="https://www.newblack.io/boat/"
    style="display: flex; flex:1;border: none"
  >
  </iframe> -->

    <iframe v-if="page.url"
            :src="page.url"
            style="display: flex; flex: 1; border: none;">
    </iframe>
  </div>
</template>
<script>
import { db } from "@/firebase";
import { collection, doc, getDoc, query, where, getDocs, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';

export default {
  data() {
    return {
      page: {},
    };
  },
  async mounted() {

    const page = await getDoc(doc(db, "pages", this.$route.params.id));
    if (page.exists()) {
      this.page = page.data();
    } else {
      alert("No such document!");
    }

  },
};
</script>
