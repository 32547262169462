<template>
  <div class="dark container">
    <div class="profile">
      <h3>Profile</h3>

      <div style="margin-bottom: 16px">
        <div class="flex-table">
          <div class="row">
            <img class="icon"
                 src="@/assets/emoji/64/1f464.png" />
            <div class="col">{{ user.data.displayName }}</div>
            <img class="icon"
                 src="@/assets/emoji/64/2709-fe0f.png" />
            <div class="col">{{ user.data.email }}</div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 16px">
        <div class="food-preference">
          <h5 class="darker">Preferences</h5>

          <div class="radio">
            <input type="radio"
                   id="default"
                   name="vegan"
                   value=""
                   v-model="profile.foodPreference" /><label for="default">
              <img class="icon"
                   src="@/assets/emoji/64/1f374.png" />None</label>
            <input type="radio"
                   id="vegetarian"
                   name="vegan"
                   value="vegetarian"
                   v-model="profile.foodPreference" /><label for="vegetarian">
              <img class="icon"
                   src="@/assets/emoji/64/1f957.png" />Vegetarian</label>
            <input type="radio"
                   id="vegan"
                   name="vegan"
                   value="vegan"
                   v-model="profile.foodPreference" />
            <label for="vegan"><img src="@/assets/emoji/64/1f96c.png"
                   class="icon" />Vegan</label>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 16px"
           class="allergies">
        <h5 class="darker">Allergies</h5>

        <button v-bind:class="{ active: profile.allergies.dairy }"
                @click="toggle('allergies', 'dairy')">
          <img src="@/assets/emoji/64/1f9c0.png"
               class="icon" />Dairy products
        </button>
        <button v-bind:class="{ active: profile.allergies.wheat }"
                @click="toggle('allergies', 'wheat')">
          <img src="@/assets/emoji/64/1f33e.png"
               class="icon" />Wheat & Gluten
        </button>
      </div>

      <div style="margin-bottom: 16px"
           class="notes">
        <h5 class="darker">Default notes</h5>
        <textarea v-model="profile.notes"></textarea>
      </div>

      <div style="margin-bottom: 16px"
           class="birthday">
        <h5 class="darker">Birthday</h5>

        <div class="select">
          <input type="checkbox"
                 v-model="profile.hide_birthday" /> Hide my birthday
        </div>




        <!-- 
        <select v-model.number="profile.birthday.year">
          <option value="-1">Prefer not to say</option>
          <option v-for="(year, idx) in availableYears" v-bind:key="idx">
            {{ year }}
          </option>
        </select> -->
      </div>

      <div style="display: block">
        <button class="cmd"
                @click="updateProfile">Save Profile</button>
        <div v-if="profileSaved"
             style="
            display: inline-flex;
            flex-direction: row;
            align-content: center;
            margin-left: 12px;
          ">
          <img src="@/assets/emoji/64/2705.png"
               class="icon" /><span>Profile saved</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { db } from "@/firebase";
import { collection, doc, getDoc, query, where, getDocs, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';

const userDbRef = collection(db, "users");

const startYear = new Date().getFullYear() - 18;
export default {
  data() {
    return {
      profile: {
        notes: null,
        foodPreference: "default",
        allergies: { wheat: false, dairy: false },
        hide_birthday: false
      },
      profileSaved: false,
      availableYears: Array.from({ length: 50 }, (_, i) => startYear - i),
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),


  },
  async mounted() {
    document.body.classList.add("profile");
    // fetch user profile
    const userProfile = await getDoc(doc(db, "users", this.user.data.uid));

    if (userProfile.exists) {
      const data = userProfile.data();
      this.profile.notes = data.notes;
      this.profile.hide_birthday = data.hide_birthday || false;
      this.profile.allergies = data.allergies || { wheat: false, dairy: false };
      this.profile.foodPreference =
        data.foodPreference ||
        (data.vegan && "vegan") ||
        (data.vegetarian && "vegetarian") ||
        "";
    }
  },
  methods: {
    toggle(where, what) {
      if (what) {
        this.profile[where][what] = !this.profile[where][what];
      } else this.profile[where] = !this.profile[where];
    },
    async updateProfile() {

      await updateDoc(doc(db, "users", this.user.data.uid),
        {
          notes: this.profile.notes || null,
          foodPreference: this.profile.foodPreference,
          allergies: this.profile.allergies,
          hide_birthday: this.profile.hide_birthday,
        }
      );




      this.profileSaved = true;
      setTimeout(() => {
        this.profileSaved = false;
      }, 5000);
    },
  },
  destroyed() {
    document.body.classList.remove("profile");
  },
};
</script>
