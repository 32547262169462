<template>
  <div class="dark fullscreen login">
    <div style="padding: 12px;">
      <h2 style="margin-bottom: 30px">
        Welcome to your<br />
        New Black life
      </h2>
      <button @click="microsoftLogin"
              style="width: 100%">
        <img class="icon"
             src="@/assets/microsoft-logo@2x.png"
             alt="Sign in with Microsoft" />
        Sign in with Microsoft</button><br />
      <button @click="googleLogin"
              style="width: 100%; margin-top: 10px">
        <img class="icon"
             src="@/assets/google-logo@2x.png"
             alt="Sign in with Google" />
        Sign in with Google
      </button>
    </div>
  </div>
</template>

<script>
import { db, auth } from "@/firebase";
import { OAuthProvider, signInWithPopup, getAdditionalUserInfo } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";



export default {
  methods: {
    googleLogin() {
      const provider = new OAuthProvider("google.com");
      this.performSignIn(provider);
    },
    microsoftLogin() {
      const provider = new OAuthProvider("microsoft.com");

      provider.setCustomParameters({
        // Optional "tenant" parameter in case you are using an Azure AD tenant.
        // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
        // or "common" for tenant-independent tokens.
        // The default value is "common".
        tenant: "5d3d4388-d011-4225-8b6a-d959f18e7656",
      });
      this.performSignIn(provider);
    },
    performSignIn(provider) {
      console.log(auth);
      signInWithPopup(auth, provider)

        .then(async (user) => {

          const details = getAdditionalUserInfo(user)


          const surname =
            details.profile.family_name ||
            details.profile.surname;
          const givenName =
            details.profile.given_name ||
            details.profile.givenName;

          const userDoc = doc(db, "users", user.user.uid);

          await setDoc(userDoc, {
            surname: surname,
            givenName: givenName,
            email: user.user.email
          });

          this.$router.replace("/");
        })
        .catch((err) => {
          alert(err.message);
        });
    },
  },
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>